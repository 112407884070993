//profile css
.cart-section {
  .cart-section-head {
    margin-bottom: 30px;
    h2 {
      margin: 0px;
      color: #000000;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 700;
    }
    p {
      margin-bottom: 0px;
      color: #000000;
      font-size: 18px;
    }
  }

  //checkout-body
  .profile-body {
    .profile-form {
      .form-group {
        .form-control {
          border-color: #d1d1d1;
          border-radius: 0px;
          font-size: 12px;
          //text-transform: uppercase;
          color: #000;
          height: 36px;
          &:hover,
          &:focus {
            border-color: #d1d1d1;
          }
        }
      }
      .checkout-btn {
        text-align: center;
        margin: 30px 0px 20px;
        button {
          border: 1px solid #e25e52;
          color: #e25e52;
          background-color: transparent;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 6px 20px;
          min-width: 250px;
          &:hover {
            background-color: #e25e52;
            color: #fff;
          }
        }
      }
    }
  }
}

.upload-btn {
  button {
    border: 1px solid #e25e52;
    color: #e25e52;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    &:hover,
    &:focus {
      background-color: #e25e52;
      color: #fff;
    }
  }
}

//profile
.profile {
  //profile-picture
  .profile-picture {
    text-align: center;
    border: 1px solid #d1d1d1;
    padding: 15px 15px 40px;
    .profile-picture-head {
      h3 {
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;
        text-transform: uppercase;
      }
    }
    .upload-btn {
      button {
        border: 1px solid #e25e52;
        color: #e25e52;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        &:hover,
        &:focus {
          background-color: #e25e52;
          color: #fff;
        }
      }
    }
  }
}

.product-preview-image {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.ReactCrop {
  max-height: 500px;
  display: flex;
  justify-content: center;
  overflow: auto !important;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }
}

.variant-combination {
  border: 1px solid;
  padding: 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .profile {
    .profile-picture {
      margin-bottom: 30px;
    }
  }
  .cart-section {
    margin-top: 30px;
  }

  .ReactCrop {
    max-height: 100%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .profile {
    .profile-picture {
      margin-bottom: 30px;
    }
  }
}
