.main-body{
    .main-content{
        .admin-card{
            .admin-card-head{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .admin-card-head-right{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    .dash-time{
                        margin-right: 15px;
                        ul{
                            margin: 0px;
                            padding: 0px;
                            display: flex;
                            align-items: center;
                            li{
                                list-style: none;
                                p{
                                    padding: 8px 20px;
                                    border: 1px solid #d0d0d0;
                                    background-color: #FFFFFF;
                                    color: #989898;
                                    text-transform: uppercase;
                                    font-size: 12px;
                                    &.active{
                                        background-color: rgba(226, 94, 82, 0.2);
                                    }
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .dash-date{
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        p {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        input {
                            margin-right: 10px;
                        }
                        .form-control{
                            border-radius: 0px;
                            border: 1px solid #d0d0d0;
                            background-color: #FFFFFF;
                            font-size: 12px;
                            padding: 0px 10px;
                            height: 34px;
                        }
                    }
                }
            }
            .admin-dash-ratio{
                .admin-dash-ratiobox{
                    background-color: #FFFFFF;
                    padding: 15px;
                    border: 0.5px solid #ced4da;
                    margin-bottom: 20px;
                    .admin-dash-ratiobox-top{
                        display: flex;
                        align-items: end;
                        margin-bottom: 25px;
                        .admin-dash-ratiobox-icon{
                            background-color: #E25E52;
                            padding: 10px;
                            width: 60px;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 15px;
                            i{
                                color: #FFFFFF;
                            }
                        }
                        .admin-dash-ratiobox-detail{
                            p{
                                color: #000000;
                                margin-bottom: 0px;
                                font-weight: normal;
                                text-transform: uppercase;
                            }
                            h3{
                                font-weight: 500;
                                color: #000000;
                                font-size: 30px;
                                span{
                                    color: #989898;
                                    font-size: 14px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                    .admin-dash-ratiobox-bottom{
                        margin-bottom: 10px;
                        .admin-dash-ratiobox-graph{
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .admin-dash-graph{
                background-color: #FFFFFF;
                padding: 15px;
                border: 0.5px solid #ced4da;
                margin-bottom: 20px;
                .admin-dash-graph-top{
                    .admin-dash-graph-heading{
                        h3{
                            color: #000000;
                            text-transform: uppercase;
                            font-size: 18px;
                            margin-bottom: 0px;
                        }
                        p{
                            color: #000000;
                            font-size: 14px;
                            margin-bottom: 0px;
                        }
                    }
                    .admin-dash-graph-ratio{
                        text-align: right;
                        h3{
                            color: #000000;
                            text-transform: uppercase;
                            font-size: 18px;
                            margin-bottom: 0px;
                        }
                        p{
                            color: #000000;
                            font-size: 14px;
                            margin-bottom: 0px;
                            span{
                                color: #E25E52;
                            }
                        }
                    }
                }
                .admin-dash-graph-img{
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
} 

//media css
@media only screen and (max-width: 767px){
    .main-body{
        .main-content{
            .admin-card{
                .admin-card-head{
                   flex-wrap: wrap;
                    .admin-card-head-right{
                        flex-wrap: wrap;
                        .dash-time{
                            ul{
                                li{
                                    a{
                                        padding: 8px 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
              
                .admin-dash-graph{
                    .admin-dash-graph-top{
                        .admin-dash-graph-heading{
                            margin-bottom: 10px;
                        }
                        .admin-dash-graph-ratio{
                            text-align: left;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    } 
}
