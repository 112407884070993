.order-heading{
  margin-bottom: 15px;
      h2{
        margin-bottom: 0px;
        color: #000000;
        text-transform: uppercase;
        font-size: 18px;
      }
      P{
        margin-bottom: 0px;
      }
    .order-edit{
      text-align: right;
      .orange-outline-btn{
        min-width: auto;
      }
    }
}

//cart
    .cart-box{
      display: flex;
      justify-content: space-between;
      border: 1px solid #D1D1D1;
      padding: 10px;
      margin-bottom: 15px;
      width: auto;
      margin: 0px auto 15px;
      overflow: auto;
      .cart-box-detail{
        display: flex;
        .cart-box-img{
          margin-right: 15px;
          width: 80px;
          img{
            width: 100%;
          }
        }
        .cart-box-item-detail{
          margin-right: 15px;
          h2{
            color: #000000;
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 0px;
          }
          p{
            font-size: 14px;
            color: #989898;
            font-weight: 500;
            margin-bottom: 0px;
            span{
              color: #000000;
            }
          }
        }
      }
      .cart-box-item-price{
        text-align: right;
        min-width: 150px;
        padding-right: 15px;
        p{
          margin-bottom: 0px;
          color: #000000;
          font-weight: 600;
          font-size: 25px;
          line-height: 25px;
        }
      }
    }

//order-detail-left
.order-detail-left{
  .table{
    tr{
      position: relative;
      td{
        font-size: 16px;
        color: #000;
        padding: 5px;
        border: 0px;
        &:nth-child(1){
          
        }
      }
      .form-field-error-tr {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 20px;    
      }
    }
  }
}

//order-detail-right
.order-detail-right{
  .table{
    border: 1px solid #D1D1D1;
    tr{
     
      td{
        padding: 5px;
        .edit-input{
          float: right;
          max-width: 100px;
          border-radius: 0px;
          padding: 5px 10px;
          &:focus{
            outline: none;
            border-color: #ced4da;
          }
        }
        &:nth-child(1){
          
        }
        &:nth-child(2){
          text-align: right;
        }
      }
    }
  }
}






//media css
@media only screen and (max-width: 767px) {
  .order-heading{
    p{
      margin-bottom: 15px;
    }
      .order-edit{
        text-align: left;
      }
  }
}

