.product-category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;

  label {
    margin: 0;
    margin-right: 10px;
  }

  p {
    margin: 0;
    cursor: default;
  }

  li {
    cursor: pointer;
    border-bottom: 1px solid #ddd;

    p {
      margin: 0;
    }

    span {
      font-size: 12px;
      color: blue;
      text-decoration: underline;
    }
  }
}

.breadcrumb {
  margin: 0;
  min-height: 40px;
}
.breadcrumb-item {
  text-align: center;
  font-size: 14px;
  color: #fe8264;
  cursor: pointer;
  text-decoration: none; 
}

.product-variant {
  border: solid;
  display: flex;
}

.doba-main {
  li {
    padding: 10px;
    cursor: pointer;

    &.active {
      border: 2px solid #e25e52;
    }
  }
}

.doba-search-list-ul {
  border: 1px solid #ddd;
  padding: 0 10px;
  margin: 10px 0;

  li {
    border-bottom: 1px solid #ddd;
    align-items: center;
    justify-content: space-between;

    a {
      color: #e25e52;
      margin-right: 10px;
      white-space: normal;
    }

    label {
      input {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.doba-search-main-show {
  flex-flow: column;
  /* padding: 0; */
  line-height: 40px;
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 10px;
  padding: 0 15px;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    a {
      color: #e25e52;

      p {
        white-space: break-spaces;
        margin: 0;
      }
    }

    label {
      input {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.disable-btn {
  opacity: 0.5;
}

.doba-img-set {
  p {
    img {
      width: 400px;
    }
  }
}

.doba-product-search {
  height: 200px;
  border: 4px solid #e25e52;
  padding: 5px;
  margin: 5px;
  width: 200px;
  min-width: 200px;
}

.admin-card-box {
  a{
    color: #E25E52;
    white-space: nowrap;
    margin-right: 10px;
    cursor: pointer;
  }
  .seller-desc-p {
    padding-bottom: 10px;
    line-height: normal;
    font-size: 12px;
  }
}

.anchor-para {
  text-decoration: none;
  cursor: default;
  a {
    cursor: default;
    &:hover {
      color: #e25e52;
    }
  }
}