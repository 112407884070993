//sidebar
.sidebar {
  width: 250px;
  background-color: #fff;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  &.sidebar-show {
    display: block;
  }
  .sidebar-head {
    padding: 25px;
    text-align: center;
    border-bottom: 1px solid #ced4da;
    position: relative;
    img {
      width: 100%;
      max-width: 150px;
    }
    .toggle-close-btn {
      display: none;
      button {
        border: 0px;
        background-color: transparent;
        color: #000;
        padding: 5px 10px;
        font-size: 22px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .sidebar-menu {
    margin-top: 50px;
    padding-bottom: 20px;
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        a {
          color: #000000;
          background-color: #fff;
          padding: 10px 15px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          border-right: 3px solid transparent;
          display: flex;
          align-items: center;
          position: relative;
          svg {
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin-right: 10px;
            fill: #000;
          }
          &:hover,
          &.active {
            background-color: rgba(226, 94, 82, 0.1);
            color: #e25e52;
            border-right-color: #e25e52;
            svg {
              fill: #e25e52;
            }
          }
        }
        span {
          border: 100px;
          background-color: #e25e52;
          font-size: 10px;
          position: absolute;
          top: 10px;
          right: 9px;
          color: #fff;
          border-radius: 100%;
          min-width: 20px;
          min-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  //sidebar
  .sidebar {
    display: none;
    .sidebar-head {
      .toggle-close-btn {
        display: block;
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .sidebar-menu {
    overflow: auto;
    height: 80%;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #d0d0d0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e25e52;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #e25e52;
    }
  }
}
