@import "../Styles/variables.scss";
//body
body {
  background-color: #f8f8f8;
  .form-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .react-date-picker__wrapper {
      border: none;
    }
    .react-date-picker__calendar {
      width: 100%;
    }
  }
  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

//forms
.form-group {
  position: relative;
  label {
    color: #000000;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 500;
    text-transform: uppercase;
    sup {
      color: #e25e52;
    }
  }
  .form-control {
    border: 1px solid #d1d1d1;
    height: 40px;
    border-radius: 0px;
    font-size: 13px;
  }
  .form-textarea {
    min-height: 120px;
    resize: none;
    text-transform: uppercase;
  }
  .drag-drop {
    z-index: 0;
    position: relative;
    .drag-drop-img {
      margin-bottom: 20px;
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
  }
}

//custom-radio-input
.custom-radio-input {
  display: flex;
  p {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      display: contents;
      input {
        margin-left: 5px;
        accent-color: #e25e52;
      }
      @supports (-moz-appearance: none) {
        input[type="radio"] {
         margin-top: 4px;
        }
      }
      input[type="radio"]:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -9px;
        left: -1px;
        position: relative;
        background-color: white;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid #e25e52;
      }

      input[type="radio"]:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -9px;
        left: -1px;
        position: relative;
        background-color: #e25e52;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid #e25e52;
      }
    }
  }
}

.image-item {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  video {
    height: 100%;
    margin-right: 20px;
  }
  img {
    margin-right: 20px;
    min-width: 200px;
    min-height: 150px;
    height: 150px;
    object-fit: cover;
    object-position: top;
  }
  audio {
    min-width: 300px;
    width: 300px;
    margin-right: 20px;
  }
  .image-item__btn-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column;
    button {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .file-box-select {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    margin-right: 20px;
    span {
      font-size: 12px;
    }
    p {
      margin-bottom: 0;
    }
    .file-icon-tuff {
      font-size: 20px;
    }
  }
}

.space-add-elite {
  margin-top: 50px;
}

.border-add-select {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 2px;
  .select-head-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      span {
        margin-right: 10px;
      }
    }
  }
}

//colors
.orange {
  color: #e25e52;
}

.black {
  color: #000;
}

.disable-btn {
  opacity: 0.5;
}

//buttons
.orange-outline-btn {
  border: 1px solid #e25e52;
  color: #e25e52;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover,
  &:focus {
    background-color: #e25e52;
    color: #fff;
  }
}

.hyper-link-btn {
  border: none;
  color: #000;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  &:hover,
  &:focus {
    color: #e25e52;
  }
}

.orange-btn {
  border: 1px solid #e25e52;
  color: #fff;
  background-color: #e25e52;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover {
    background-color: #fff;
    color: #e25e52;
  }
}

//main-body
.main-body {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  .main-content {
    width: calc(100% - 250px);
    margin-left: 250px;
    .main-header {
      background-color: #ffffff;
      padding: 15px;
      margin: 15px 0px;
      border: 0.5px solid #ced4da;
      .header-top-left {
        display: flex;
        align-items: center;
        justify-content: left;
        .toggle-btn {
          margin-right: 15px;
          display: none;
          button {
            border: 0px;
            background-color: #e25e52;
            color: #fff;
            padding: 5px 10px;
          }
        }
        .header-top-search {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          button {
            background-color: transparent;
            color: #e25e53;
            border: none;
            padding: 0px;
            height: 25px;
            width: 25px;
            padding-right: 10px;
          }
          .form-control {
            border-radius: 0px;
            border-color: #00000029;
            border: 0px;
            height: 30px;
            border-bottom: 1px solid #777777;
            padding-left: 0px;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
          }
        }
      }

      .header-top-menu {
        .notifications-alert-icon {
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 20px;
          li {
            list-style: none;
            .dropdown {
              position: relative;
              p {
                &::after {
                  display: none;
                }
              }
              ul {
                min-width: 330px;
                padding: 0;
                max-height: 340px;
                overflow: auto;
                position: absolute;
                top: 30px;
                right: 0px;
                background-color: #fff;
                border-radius: 10px;
                z-index: 15;
                box-shadow: 0px 2px 8px #00000022;
                /* width */
                &::-webkit-scrollbar {
                  width: 8px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background: #d0d0d0;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #e25e52;
                  border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #e25e52;
                }
                li {
                  padding: 10px;
                  border-bottom: 1px solid #ccc;
                  margin: 0;
                  cursor: pointer;
                  &.active {
                    color: #fff;
                    background-color: #e25d56;
                  }
                  .color-link-orange {
                    color: #e25e52;
                    margin: 0;
                  }
                  &:last-child {
                    border: 0;
                  }
                }
              }
            }
            p {
              color: #000000;
              font-size: 16px;
              cursor: pointer;
              &.header-addtocart-item {
                position: relative;
                span {
                  background-color: #e25e52;
                  font-size: 10px;
                  position: absolute;
                  top: -7px;
                  right: -11px;
                  color: #fff;
                  border-radius: 100%;
                  min-width: 20px;
                  min-height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .main-footer {
      text-align: right;
      margin-top: 10px;
      p {
        margin-bottom: 0px;
        color: #000000;
        font-size: 13px;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
    .admin-card {
      .admin-card-head {
        h2 {
          color: #000000;
          text-transform: uppercase;
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .admin-card-box {
        background-color: #ffffff;
        padding: 15px;
        border: 0.5px solid #ced4da;
      }
    }
  }
}

//Custom Radio Buttons
.custon-radio {
  display: block;
  position: relative;
  padding-right: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  user-select: none;
  color: #989898 !important;
  margin-right: 15px;
}
.custon-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custon-radio .checkmark {
  position: absolute;
  top: 1px;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}

.custon-radio input:checked ~ .checkmark {
  background-color: #e25e52;
}
.custon-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custon-radio input:checked ~ .checkmark:after {
  display: block;
}
.custon-radio .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-radio-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label-radio-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

//Custom Radio Buttons end

//vendor-table
.vendor-table {
  .vendor-table-head {
    .entries-drop {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      margin-right: 10px;
      label {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0px;
      }
      select {
        text-transform: capitalize;
      }
      .entries-controls {
        border: 1px solid #d1d1d1;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      p {
        color: #000000;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .vendor-table-head-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .vendor-table-search {
        margin-bottom: 15px;
        .form-control {
          border: 1px solid #d1d1d1;
          border-radius: 0px;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 500;
          width: auto;
          height: 25px;
        }
      }
      .download-statement-btn {
        margin-bottom: 15px;
      }
    }
  }
  .vendor-table-body {
    .table {
      thead {
        background-color: rgba(226, 94, 82, 0.1);
        tr {
          td {
            color: #989898;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
          }
        }
      }
      tbody {
        tr {
          td {
            color: #000000;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
            max-width: 250px;
            word-break: break-all;
            p {
              margin-bottom: 0px;
            }
            a {
              color: #e25e52;
              &:hover {
                color: #000000;
              }
            }
            .vendor-table-btn {
              .orange-outline-btn {
                min-width: auto;
                font-size: 12px;
                text-transform: capitalize;
                padding: 2px 20px;
              }
            }
          }
        }
      }
      .vendor-table-check {
        .custon-radio {
          margin: 0px;
          padding: 0px;
          display: block;
          .checkmark {
            left: 0;
            top: -8px;
            border: 1px solid #989898;
            border-radius: 0px;
            background-color: #fff;
            &:after {
              border-color: #e25e52;
            }
          }
        }
      }
    }
  }
}

//paymentdetails-modal
.paymentdetails-modal {
  background-color: #00000059;
  .modal-dialog {
    .modal-content {
      border-radius: 0px;
      border: 0px;
      .modal-header {
        border-bottom: 0px;
        position: relative;
        padding: 25px 20px 5px;
        .paymentdetails-heading {
          text-align: center;
          width: 100%;
          h4 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0px;
          }
        }
        .close {
          position: absolute;
          top: 22px;
          right: 15px;
          padding: 0px;
          margin: 0px;
          color: #000;
          opacity: 1;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .modal-body {
        padding-bottom: 25px;
        .paymentdetails-table {
          .table {
            tbody {
              tr {
                td {
                  border: 1px solid #f3f2f2;
                  h5 {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 3px;
                    white-space: nowrap;
                    color: #000;
                  }
                  p {
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: #000;
                    span {
                      text-transform: uppercase;
                      display: block;
                      &.green {
                        color: #19bf00;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.form-field-error {
  color: red;
  font-size: 13px;
}

@media only screen and (max-width: 1024px) {
  //main-body
  .main-body {
    .main-content {
      width: 100%;
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-search {
          margin-bottom: 15px;
        }
      }
      .main-footer {
        text-align: center;
      }
    }
  }
  //vendor-table
  .vendor-table {
    .vendor-table-head {
      .vendor-table-head-right {
        justify-content: flex-start;
        .vendor-table-search {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-left {
          .header-top-search {
            margin-bottom: 15px;
          }
        }
      }
      .main-footer {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-left {
          .toggle-btn {
            display: block;
          }
        }
      }
    }
  }
}

//custom-checkbox
.custom-check {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  color: #989898;
  font-weight: 500;
  text-transform: uppercase;
}

.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-check .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #777777;
}

.custom-check input:checked ~ .checkmark {
  background-color: #e25e52;
  border-color: #e25e52;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked ~ .checkmark:after {
  display: block;
}

.custom-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.files-error {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

.border-add-new {
  border-bottom: 1px solid #ccc;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  margin: 15px 0;
  input {
    border: 1px solid #d1d1d1;
  }
}

.alert-fixed {
  position: fixed;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  z-index: 999;
  top: 50px;
  right: 50px;
  p {
    margin: 0px;
  }
  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    margin: 0px;
  }
}

.calendar-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: none;
        p {
          margin: 0;
        }
      }
      .modal-body {
        padding-bottom: 40px;
        .modal-btn-group {
          text-align: center;
          button {
            margin: 0px 15px;
            border-radius: 0;
            &:hover {
              border-color: $color-main-button;
            }
          }
        }
        .details-slot {
          text-align: center;
          margin-bottom: 20px;
          p {
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 5px;
          }
          h5 {
            font-weight: 400;
            margin-top: 5px;
            font-size: 18px;
          }
          button {
            margin: 0 6px;
          }
        }
        .button-center {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
        .schedule-show-b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          padding: 12px 10px;
          text-transform: uppercase;
          font-size: 14px;
          &.active {
            background-color: #fcefed;
          }
          p {
            margin: 0;
            width: 100%;
          }
          .schedule-check {
            display: flex;
            align-items: center;
            width: 100%;
            p {
              margin: 0;
              margin-left: 5px;
            }
          }
        }
        .meeting-p {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.product-detail-radio {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .form-check {
    margin-right: 20px;
    label {
      display: flex;
      align-items: center;
      input {
        width: 14px;
        height: 14px;
        &:focus {
          outline: none;
        }
      }
      input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid #e25e52;
        background-color: #fff;
        border-radius: 50%;
      }

      /* appearance for checked radiobutton */
      input[type="radio"]:checked {
        background-color: #e25e52;
      }
    }
  }
}

.represent-status {
  list-style: none;
  position: absolute;
  padding: 0;
  top: 47px;
  left: 4px;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    .color-bar {
      width: 10px;
      height: 10px;
      background-color: #e25e52;
      margin-right: 4px;
    }
    .grey-color {
      background-color: grey;
    }
    .green-color {
      background-color: #00c12a;
    }
    .purple-color {
      background-color: #0423ff;
    }
    .yellow-color {
      background-color: #e4a900;
    }
    p {
      margin: 0;
      font-size: 10px;
    }
  }
}

.search-pills-category {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e25e52;
  margin-bottom: 10px;
  li {
    border: 1px solid #e25e52;
    border-radius: 5px;
    padding: 0px 4px;
    cursor: pointer;
    margin-right: 5px;
    text-transform: uppercase;
    span {
      border-left: 1px solid #e25e52;
      padding-left: 4px;
      margin-left: 4px;
    }
    &:hover {
      background-color: #ffebea;
    }
  }
}

.user-profile-img {
  margin-bottom: 20px;
  img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
  }
}

.sidebar-submenu-link {
  padding-left: 25px !important;
}

.variant-combination {
  min-height: 460px;
  .form-group {
    .variant-img {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 60%;
      }
    }
  }
}

.item-custom-desc {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.note-ecom {
  color: #fe504f;
  i,
  strong {
    margin-right: 5px;
  }
}

.react-calendar__navigation__arrow {
  font-size: 20px !important;
  color: #e25e52 !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #e25e52 !important;
}

.react-date-picker {
  margin-right: 10px;
  text-transform: uppercase;
  height: 33px;
}

.react-calendar__tile--now {
  background: #e25e5233 !important;
}

.react-date-picker__calendar {
  z-index: 12 !important;
}

.react-date-picker__inputGroup__input {
  width: 35px !important;
  text-align: center !important;
}

.vendor-table-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  a {
    &:last-child {
      margin: 0;
    }
  }
}

.note-admin-p {
  font-size: 14px;
  span {
    color: #ff0000;
    font-weight: 600;
  }
}

.crop-image-view {
  height: 260px;
  .ReactCrop {
    height: 260px !important;
    border: 1px solid;
    display: inline-block;
    img {
      height: 260px !important;
      object-fit: contain;
    }
  }
}

.button-align-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  word-break: break-word !important;
  max-width: 100% !important;
  vertical-align: middle;
  word-break: normal;
  gap: 10px;
}

.add-section-cursor {
  cursor: pointer;
  color: #e25e53;
}
.border-seprate {
  padding-bottom: 15px;
}

.border-table-status {
  border: 1px solid #e25e53;
  .table {
    margin: 0;
  }
}

.pendingColor {
  color: #ffb075;
}

.rejectedColor {
  color: #ff0000;
}

.approvedColor {
  color: #00c12a;
}

//seminar-info
.seminar-info {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      h3 {
        font-size: 16px;
        color: #000;
        font-weight: 500;
        margin-bottom: 3px;
        min-width: 120px;
      }
      p {
        font-size: 14px;
        color: #000;
        font-weight: 400;
        margin-bottom: 0px;
      }
      a {
        font-size: 14px;
        color: #e25e52;
        font-weight: 400;
        text-decoration: underline;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.frequency {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  .heading {
    margin: 0px;
    min-width: 150px;
  }
  .check-outer {
    margin: 10px;
    border: 1px solid #707070;
    padding: 10px;
    min-width: 120px;
    .outer-label {
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 12px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #000;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
    .checkmark:after {
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .outer-label input:checked ~ .checkmark:after {
      display: block;
    }
    .outer-label input ~ .checkmark {
      background-color: #fff;
      border: 2px solid black;
      border-radius: 5px;
    }

    /* When the checkbox is checked, add a blue background */
    .outer-label input:checked ~ .checkmark {
      border: 2px solid #000;
      border-radius: 5px;
    }
  }
}

.custom-label-product {
  max-width: 150px;
  margin-right: 10px !important;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  white-space: nowrap;
}

.black-bg-op {
  background-color: rgba($color: #000000, $alpha: 0.02);
}

.tooltip-custom {
  position: relative;
  i {
    color: #e25e53;
    cursor: pointer;
    margin-left: 5px;
    transition: 0.3s;
    font-size: 14px;
  }
  &:hover p {
    display: block;
  }
  &:hover .arrow-down {
    display: block;
  }
  p {
    border-radius: 6px;
    padding: 10px;
    position: relative;
    background-color: #fff;
    z-index: 1;
    width: 190px;
    word-break: break-word;
    text-align: left;
    font-size: 12px;
    text-transform: capitalize;
    box-shadow: 0 2px 5px #cec9c98c;
    border: 1px solid #ddd;
    font-weight: 400;
    display: none;
    margin: 0;
    white-space: normal;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ddd;
    position: absolute;
    bottom: -9px;
    left: 9px;
    display: none;
  }
  .together-tooltip {
    position: absolute;
    bottom: 30px;
    left: -6px;
  }
}

.message-popup {
  ul {
    padding: 10px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    flex-wrap: wrap;
    height: 150px;
    overflow: auto;
    li {
      border: 2px solid #ccc;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 2px 5px;
      span {
        border-left: 1px solid #ccc;
        padding-left: 5px;
        color: red;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .form-group {
    textarea {
      resize: none;
      height: auto !important;
    }
  }
}

.positionRelative {
  position: relative;
}

.dollar-set {
  position: absolute;
  top: 14px;
  left: 8px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
  }
}

.border-show-seprate {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.theme-color {
  color: #e25e53 !important;
}

.theme-delete {
  color: #e25e53;
  fill: #e25e53;
  border: none;
  cursor: pointer;
}

.recurring {
  border: 1px solid #707070;
  padding: 10px;
  display: flex;
  overflow-y: scroll;
  .react-time-picker__wrapper {
    border: none;
  }
}

.week-card {
  &.disabled {
    opacity: 0.4;
    border: 1px solid black;
  }
  .week-bottom {
    border: 1px solid rgba($color: #000000, $alpha: 0.5);
    margin-bottom: 5px;
    padding: 5px;
  }
  .day {
    padding: 10px;
    color: #fff;
    text-align: center;
    &.active {
      background-color: #e25e53;
    }
    &.disabled {
      opacity: 0.4;
      border: 1px solid black;
      background-color: #000;
    }
  }
  background: #ffffff;
  width: 280px;
  min-width: 280px;
  margin: 10px;
}
.add-new {
  border: 1px solid rgba($color: #000000, $alpha: 0.5);
  border-radius: 5px;
  padding: 6px 10px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

.pl25 {
  padding-left: 25px;
}

.bd-t {
  border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.bb1 {
  border-bottom: 1px solid #ddd;
}

.plant-type-option {
  text-align: center;
  max-width: 250px;
  .dropdown {
    .btn {
      background-color: #f4f4f4;
      border-radius: 0px;
      color: #acacac;
      font-weight: 500;
      border-color: #f4f4f4;
      margin: 0px auto;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      position: relative;
      .add-icon {
        position: absolute;
        top: 7px;
        right: 7px;
        color: #e25d56;
        content: " ";
      }
      &:after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    ul {
      padding: 0;
      width: 100%;
      li {
        padding: 5px;
        cursor: pointer;
        a {
          color: #000;
        }
        &.active {
          background-color: #e25d56;
          color: #fff;
        }
        &:hover {
          background-color: #e25d56;
          color: #fff;
        }
      }
    }
    .form-field-error {
      color: red;
      font-size: 13px;
    }
  }
}
.white-bg {
  padding: 10px;
  .react-time-picker__wrapper {
    border: none;
  }
}
.blur {
  background-color: #e9ecef;
  opacity: 1;
}

.time-slot-modal {
  background-color: #eaeaea;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 6px;
    li {
      background-color: #fff;
      padding: 8px;
      font-size: 12px;
      text-align: center;
      color: #000;
      margin: 0 10px 10px 0;
      flex-wrap: wrap;
    }
  }
}

.upload-gallery {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  overflow-y: auto;
  padding: 20px 0;
  .upload-gallery-card {
    background-color: rgba(#000000, 0.05);
    border-radius: 10px;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    a {
      display: block;
      cursor: pointer;
    }
    video {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    .file-name {
      padding: 10px;
      span {
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      i {
        color: #000;
        font-size: 22px;
      }
      &:hover {
        i {
          color: #e25e52;
        }
      }
    }
  }
}

//add product new style ------------
.add-product-button-align {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 15px;
  margin-top: 5px;
  button {
    height: 36px;
  }
}

.make-global-attribute {
  border: 1px solid #d1d1d1;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .make-global-sub {
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    height: 100%;
    .form-group {
      margin-right: 30px !important;
    }
    h5 {
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 30px;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 12px;
      min-width: 70px;
      word-break: break-word;
    }
    button {
      height: 40px;
    }
  }
}

.add-product-table {
  .table {
    font-size: 14px;
    thead {
      background-color: #e6e6e6;
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          .variant-label {
            display: flex;
            align-items: center;
            gap: 10px;
            border: 1px solid #d1d1d1;
            padding: 6px;
            height: 40px;
            p {
              background-color: #e6e6e6;
              padding: 4px 10px;
              margin: 0;
              font-size: 12px;
            }
          }
          button {
            height: 40px;
          }
        }
      }
    }
  }
}

.product-global-img {
  border: 1px solid #d1d1d1;
  overflow: hidden;
  width: 80px;
  min-width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.global-img-sm {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
  .hover-global-show {
    display: none;
  }
  &:hover {
    img {
      display: none;
    }
    .hover-global-show {
      display: block;
      background-color: $color-main-button;
      border: 1px solid $color-main-button;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }
      svg {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 0;
      }
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

//custom toggle style -----------
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e25e52;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e25e52;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-main {
  display: flex;
  align-items: center;
  p {
    margin: 0 10px 0 0;
  }
  label {
    margin: 0;
  }
}

.note-ecom-margin {
  display: inline-block;
  margin-bottom: 12px;
}

.ratings-all-show {
  max-height: 460px;
  overflow: auto;
}

.box-all-ratings {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 14px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px 15px;
  position: relative;
  margin-bottom: 10px;
  &:last-child {
    margin: 0;
  }
  button {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #e25e52;
    i {
      color: #fff;
    }
  }
}

.vendor-select-custom {
  min-width: 450px;
  width: 450px;
  label {
    white-space: nowrap;
  }
  select {
    width: 300px;
    min-width: 300px;
  }
}

.height-textarea {
  height: 120px !important;
  resize: none;
}

.cursor-default{
  cursor: default !important;
}

