//signup
.signup{
  max-width: 500px;
  margin: 40px auto 10px;
  border: 0.5px solid #989898;
  padding: 40px;
  background-color: #fff;
  .signup-head{
    text-align: center;
    margin-bottom: 40px;
    .subcciety-logo{
      margin-bottom: 20px;
      img{
        width: 100%;
        max-width: 200px;
      }
    }
    h2{
      color: #000000;
      font-size: 20px;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-weight: 500;
    }
    p{
      color: #000000;
      font-size: 12px;
      margin-bottom: 0px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .signup-mid{
    h3{
      color: #000000;
      font-size: 14px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .form-group{
      .form-control{
        border: 0px;
        font-size: 12px;
        border-bottom: 1px solid #D1D1D1;
        border-radius: 0px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 500;
        color: #000;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #989898;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #989898;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #989898;
      }
      span{
          color: red;
          font-size: 12px;
        
      }
    }
  }
  .signup-bottom{
    .signup-submit{
      margin: 25px 0px 15px;
      button{
        border: 1px solid #E25E52;
        color: #E25E52;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px 20px;
        width: 100%;
        &:hover{
          background-color: #E25E52;
          color: #fff;
        }
      }
    }
    .already-account-link{
      text-align: center;
      margin-top: 25px;
      p{
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        a{
          color: #E25E52;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .forgot-password{
      text-align: center;
      a{
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #E25E52;
          text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
 
.signup-trums-check{
  display: flex;
  .sign-check{
    margin-right: 15px;    
  }
}

}

.signup-footer{
  text-align: center;
  p{
    color: #000000;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 500;
  }
}



//custom-checkbox
.custom-check {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  color: #989898;
  font-weight: 500;
  text-transform: uppercase;
}

.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-check .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #777777;
}


.custom-check input:checked ~ .checkmark {
  background-color: #E25E52;
  border-color: #E25E52;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked ~ .checkmark:after {
  display: block;
}

.custom-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}